<template>
  <div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />

      <div class="my-10 mx-auto px-20 py-10 grid grid-cols-3">
        <!-- <div class="mb-4"><strong>Articles currently published</strong></div>

        <div v-for="item in GET_BLASTDATA" :key="item.id" @click="viewDetail(item.id)" class="shadow my-5 rounded p-5" :class="[pushState(item) ? 'bg-gray-100' : 'bg-gray-300' ]">
          <div class="w-full">
            <strong>{{ item.article_title }}</strong>
          </div>
          <div class="w-full">
            <small>{{ pushLabel(item) }}</small>
          </div>
        </div> -->
        <div v-for="(record,index) in datas" :key="`record${index}`">
          <div class="pb-4"><b>{{ record.candidateName }}</b></div>
          <div class="border-b pb-4">{{ record.average }} %</div>
          <div v-for="(dataPerSource,idx) in record.valuePerSource" :key="`recordpersource-${record.candidateName}-${idx}`" class="py-4 border-b">
            <div>{{ dataPerSource.source }}</div>
            <div class="my-2">
              <input :name="`inputlitbang${index}-${idx}`" type="number" :value="dataPerSource.total" :tabindex="tabIndexes[`${idx}-${index}`]" @focusout="ev => updateData(dataPerSource.id, ev.target.value)" class="border p-2 rounded" :readonly="pushingNewData" />
            </div>
          </div>
        </div>

        <!-- <div class="shadow my-5 rounded bg-gray-300 p-5">
          <div class="w-full">
            <strong>Title Algo Research</strong>
          </div>
          <div class="w-full">
            <small>16 November 2023</small>
          </div>
        </div>

        <div class="shadow my-5 rounded bg-gray-100 p-5">
          <div class="w-full">
            <strong>Title Algo Research</strong>
          </div>
          <div class="w-full">
            <small>16 November 2023</small>
          </div>
        </div>

        <div class="shadow my-5 rounded bg-gray-300 p-5">
          <div class="w-full">
            <strong>Title Algo Research</strong>
          </div>
          <div class="w-full">
            <small>16 November 2023</small>
          </div>
        </div> -->

      </div>
      <div class="my-10 mx-auto px-20 py-10" v-show="!pushingNewData">
        <!-- <button type="button" @click="pushData()">save</button> -->
        <loading v-show="pushingNewData" />
        <ButtonSolidBox
            class="py-4 w-full"
            color="secondary"
            @click="pushData()"
            v-show="!pushingNewData"
          >
            Save
          </ButtonSolidBox>
      </div>

    </div>
  </div>
</template>
<style scoped>
@media (min-width: 1440px) {

}</style>
<script>
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import Loading from '@/components/Loading.vue'
import { createNamespacedHelpers } from 'vuex'

const litbang = createNamespacedHelpers('litbang')

export default {
  components: {
    TheSideNavigationBar,
    TheContentTopNavigationBar,
    ButtonSolidBox,
    Loading
  },
  computed: {
    ...litbang.mapGetters(['GET_RECORDS', 'GET_FETCH', 'GET_READY'])
  },
  data () {
    return {
      inputValues: [],
      datas: {},
      tabIndexes: {},
      pushingNewData: false
    }
  },
  methods: {
    ...litbang.mapActions(['fetchRecord', 'pushNewData']),
    updateData (id, value) {
      if (!isNaN(value)) {
        for (let x = 0; x < this.inputValues.length; x++) {
          if (this.inputValues[x].id === id) {
            this.inputValues[x].value = parseFloat(value).toFixed(2)
            return
          }
        }
        this.inputValues.push({ id: id, value: parseFloat(value).toFixed(2) })
        console.log(this.inputValues)
        // if (this.inputValues.length === 0) {
        //   this.inputValues.push({ id: id, value: parseFloat(value).toFixed(2) })
        // } else {

        // }
      }
    },
    pushData () {
      if (this.inputValues.length > 0) {
        console.log('store new data')
        this.pushingNewData = true
        this.pushNewData(this.inputValues).then(result => {
          console.log(result)
          this.inputValues = []
          this.fetchData()
          this.pushingNewData = false
        })
      } else {
        console.log('no data to store')
      }
    },
    fetchData () {
      this.fetchRecord().then(result => {
        if (Array.isArray(result.data.data)) {
          const candidateTotal = result.data.data.length
          let totalData = 0
          result.data.data.forEach((val, i) => {
            totalData = totalData + parseInt(val.valuePerSource.length)
          })
          const totalRow = totalData / candidateTotal
          const totalCol = candidateTotal
          // indexing tabindex
          let counter = 1
          for (let x = 0; x < totalRow; x++) {
            for (let y = 0; y < totalCol; y++) {
              this.tabIndexes[`${x}-${y}`] = counter
              counter++
            }
          }
          this.$nextTick().then(() => {
            this.datas = result.data.data
          })
        }
      })
    }
    // pushLabel (item) {
    //   if (item.mail_blast == null) {
    //     return 'ready to blast'
    //   }
    //   if (item.mail_blast.subject !== null) {
    //     return 'email sent'
    //   }
    //   if (item.mail_blast.subject == null) {
    //     return 'ready to blast'
    //   }
    // },
    // pushState (item) {
    //   if (item.mail_blast == null) {
    //     return true
    //   }
    //   if (item.mail_blast.subject !== null) {
    //     return false
    //   }
    //   if (item.mail_blast.subject == null) {
    //     return true
    //   }
    // },
    // viewDetail (contentId) {
    //   this.$router.push({
    //     name: 'route.dashboard.articleblast.update',
    //     params: { id: contentId }
    //   })
    // }
  },
  created () {
    console.log('triggered')
    this.fetchData()
  }
}
</script>
