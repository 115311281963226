<template>
  <div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8 py-5">
        <div class="w-full flex flex-col">
          <div>
            <small>Enter new market index name</small>
          </div>
          <div class="w-full flex">
            <div class="w-4/5 mr-4">
              <InputTextBox
                type="text"
                class="align-middle flex-grow"
                @input="val => updateIndexName(val)"
                placeholder="Enter new market index name"
              ></InputTextBox>
            </div>
            <div class="w-1/5">
              <ButtonSolidBox
                class="w-full"
                color="secondary"
                @click="saveNewMarketIndex()"
              >
                Add
              </ButtonSolidBox>
            </div>
          </div>
          <div class="mb-12 w-full">
              <Loading :show="!MARKET_INDEX_READY" />
              <Alert relative normal v-show="create.success && create.complete" type="success">New market index name saved.</Alert>
              <Alert relative normal v-show="!create.success && create.complete">Failed to save new market index name</Alert>
          </div>
          <div class="block" v-show="MARKET_INDEX_READY">
            <Loading :show="!MARKET_INDEX_READY" />
            <Alert relative normal v-show="newMarketData.success && newMarketData.complete" type="success">Market data updated.</Alert>
            <Alert relative normal v-show="!newMarketData.success && newMarketData.complete">Failed to update market data</Alert>
            <ButtonSolidBox
              class="float-right py-2 ml-8 mb-5"
              color="secondary"
              @click="toggleNewValueMode()"
              v-show="!editMode"
            >
              {{ !newValueMode? 'New value': 'Done' }}
            </ButtonSolidBox>
            <ButtonSolidBox
              class="float-right py-2 ml-8 mb-5"
              color="gray-500"
              @click="toggleEditMode()"
              v-show="!newValueMode"
            >
              {{ !editMode? 'Edit Current': 'Done' }}
            </ButtonSolidBox>
            <ButtonSolidBox
              class="float-right py-2 ml-8 mb-5"
              color="red-500"
              @click="resetAlterState()"
              v-show="editMode || newValueMode"
            >
              cancel
            </ButtonSolidBox>
            <table class="w-full border-t table-auto">
              <thead>
                <tr class="border-b">
                  <th class="py-2">Ticker</th>
                  <th>Current</th>
                  <th>Previous</th>
                  <th>Movement</th>
                  <th>Current value date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val,index) in GET_MARKET_INDEXES" :key="val.id" class="border-b">
                  <td class="text-center">{{ val.index_name }}</td>
                  <td class="text-center"><input v-model="val.current_value" @input="valueChanged(index)" class=" py-4 px-2" :class="{'border-l border-r': (editMode||newValueMode), 'border-none': (!editMode&&!newValueMode)}" :readonly="(!editMode&&!newValueMode)" :placeholder="setPlaceholder()" /></td>
                  <td class="text-center">{{ val.prev_value!= null? val.prev_value:'-' }}</td>
                  <td class="text-center">
                    {{ val.comparison_percent }}
                  </td>
                  <td class="text-center">{{ val.current_value_date!= null? val.current_value_date:'-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <ListMenuBar
          search-placeholder-label="Search user by name, NPWP"
          @search="(val) => search(val)"
          @search-reset="() => reset()"
          @filter-changed="(val) => filterChanged(val)"
          style-dropdown-content="min-width:210px"
          :filter="true"
          :filter-data="filter"
          main-context-button-label="Add User"
          :main-context-button-target="{
            name: 'route.dashboard.user.editor.create'
          }"
          use-main-context-button
        ></ListMenuBar> -->
        <!-- <div class="px-12 py-12 my-8 bg-white rounded">
          <ListTableSimple @row-selected="showDetail" :ready="INTERNAL_USER_READY" :items="GET_INTERNAL_USERS" :columns="columns"></ListTableSimple>
          <SimplePagination v-show="INTERNAL_USER_READY" :min="1" :max="GET_INTERNAL_USER_TOTAL_PAGE" :page="fetchParam.currentPage" @move="(step) => movePage(step)" />
        </div> -->
      </div>
    </div>
    <!-- <TheSideCalendarBar :range="range" hide-today @date-updated="(val) => dateRangeUpdated(val)" /> -->
  </div>
</template>
<style scoped>
@media (min-width: 1440px) {
}</style>
<script>
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import Alert from '@/components/Alert.vue'
import Loading from '@/components/Loading.vue'
import { createNamespacedHelpers } from 'vuex'

const marketIndex = createNamespacedHelpers('marketIndex')

export default {
  components: {
    InputTextBox,
    TheSideNavigationBar,
    TheContentTopNavigationBar,
    ButtonSolidBox,
    Alert,
    Loading
  },
  data: () => {
    return {
      form: {
        index_name: ''
      },
      create: {
        success: false,
        complete: false
      },
      newMarketData: {
        success: false,
        complete: false
      },
      editMode: false,
      newValueMode: false
    }
  },
  computed: {
    ...marketIndex.mapGetters(['GET_MARKET_INDEXES', 'MARKET_INDEX_READY'])
  },
  // watch: {
  //   GET_MARKET_INDEXES: {
  //     handler: function (val, oldVal) {
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    ...marketIndex.mapActions(['getMarketIndexes', 'addNewMarketIndex', 'addNewMarketData']),
    setPlaceholder () {
      return ((this.editMode || this.newValueMode) ? 'Enter value' : '')
    },
    updateIndexName (val) {
      this.form.index_name = val
    },
    valueChanged (dataIndex) {
      for (var x = dataIndex; x < this.GET_MARKET_INDEXES.length; x++) {
        if (
          this.GET_MARKET_INDEXES[x].current_value !== undefined &&
          this.GET_MARKET_INDEXES[x].current_value !== null
        ) {
          // console.log(val[x].current_value)
          const tempArrValue = String(this.GET_MARKET_INDEXES[x].current_value).split(' ')
          // console.log(tempArrValue)
          if (tempArrValue.length > 1) {
            // console.log('multi insert from index ' + x)
            this.arrangeEnteredValue(x, tempArrValue)
            this.decimalCorrection()
            this.calculateMovement()
            break
          } else if (
            tempArrValue.length === 1
          ) {
            const parseVal = parseFloat(tempArrValue[0])
            if (
              !isNaN(parseVal) &&
              this.GET_MARKET_INDEXES[x].current_value !== parseVal
            ) {
              this.GET_MARKET_INDEXES[x].current_value = parseVal
              this.decimalCorrectionSingle(x)
              this.calculateMovementSingle(x)
              console.log('value changed -S')
              break
            }
          }
        }
      }
    },
    saveNewMarketIndex () {
      // console.log(this.form.index_name)
      if (this.form.index_name !== '') {
        this.create.complete = false
        this.create.success = false
        this.addNewMarketIndex({ form: this.form }).then((result) => {
          if (result.error === '') {
            this.create.success = true
          }
          this.create.complete = true
          setTimeout(() => {
            this.create.complete = false
            this.create.success = false
          }, 3000)
        })
      }
    },
    saveNewMarketData () {
      // console.log(this.form.index_name)
      this.newMarketData.complete = false
      this.newMarketData.success = false
      this.addNewMarketData({ form: this.GET_MARKET_INDEXES }).then((result) => {
        if (result.error === '') {
          this.newMarketData.success = true
        }
        this.newMarketData.complete = true
        setTimeout(() => {
          this.newMarketData.complete = false
          this.newMarketData.success = false
        }, 3000)
      })
    },
    arrangeEnteredValue (startFrom, value) {
      // console.log('arranging from ' + startFrom)
      let valueIdx = 0
      for (var x = startFrom; x < this.GET_MARKET_INDEXES.length; x++) {
        this.GET_MARKET_INDEXES[x].current_value = value[valueIdx]
        // console.log(this.GET_MARKET_INDEXES[x].current_value)
        valueIdx++
      }
    },
    decimalCorrectionSingle (index) {
      if (this.GET_MARKET_INDEXES[index].current_value !== undefined && this.GET_MARKET_INDEXES[index].current_value !== null) {
        this.GET_MARKET_INDEXES[index].current_value = String(this.GET_MARKET_INDEXES[index].current_value).replace(/,/g, '')
      }
      if (this.GET_MARKET_INDEXES[index].prev_value !== undefined && this.GET_MARKET_INDEXES[index].prev_value !== null) {
        this.GET_MARKET_INDEXES[index].prev_value = String(this.GET_MARKET_INDEXES[index].prev_value).replace(/,/g, '')
      }
    },
    decimalCorrection () {
      for (var x = 0; x < this.GET_MARKET_INDEXES.length; x++) {
        this.decimalCorrectionSingle(x)
      }
    },
    calculateMovementSingle (x) {
      if (
        this.GET_MARKET_INDEXES[x].prev_value !== undefined &&
        this.GET_MARKET_INDEXES[x].prev_value !== null &&
        this.GET_MARKET_INDEXES[x].current_value !== undefined &&
        this.GET_MARKET_INDEXES[x].current_value !== null
      ) {
        const current = parseFloat(this.GET_MARKET_INDEXES[x].current_value).toFixed(2)
        const prev = parseFloat(this.GET_MARKET_INDEXES[x].prev_value).toFixed(2)
        console.log('current-> ' + current)
        console.log('prev-> ' + prev)
        let movement = 'UP'
        let movePercent = 0
        if (
          !isNaN(current) && (current > 0 || current < 0) &&
          !isNaN(prev) && (prev > 0 || prev < 0)
        ) {
          movePercent = parseFloat((current / prev - 1) * 100).toFixed(2)
          if (current < prev) {
            movement = 'DOWN'
            movePercent = parseFloat(movePercent).toFixed(2)
            console.log(`movePercent: ${movePercent}`)
            console.log('negative')
          }
          // push this
          if (current === prev) {
            movement = 'NONE'
          }
        } else {
          movement = 'NONE'
        }
        this.GET_MARKET_INDEXES[x].comparison_percent = movePercent
        this.GET_MARKET_INDEXES[x].movement = movement
      } else {
        this.GET_MARKET_INDEXES[x].movement = 'NONE'
      }
    },
    calculateMovement () {
      for (var x = 0; x < this.GET_MARKET_INDEXES.length; x++) {
        this.calculateMovementSingle(x)
      }
    },
    resetAlterState () {
      this.editMode = false
      this.newValueMode = false
      this.getMarketIndexes()
    },
    toggleEditMode () {
      if (this.editMode) {
        this.saveNewMarketData()
      }
      this.editMode = !this.editMode
    },
    toggleNewValueMode () {
      if (!this.newValueMode) {
        for (var x = 0; x < this.GET_MARKET_INDEXES.length; x++) {
          this.GET_MARKET_INDEXES[x].prev_value = this.GET_MARKET_INDEXES[x].current_value
          this.GET_MARKET_INDEXES[x].current_value = null
          this.GET_MARKET_INDEXES[x].current_value_date = this.moment().format('YYYY-MM-DD')
        }
      } else {
        this.saveNewMarketData()
      }
      this.newValueMode = !this.newValueMode
    }
  },
  created () {
    this.getMarketIndexes()
  }
}
</script>
