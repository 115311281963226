<template>
  <div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8">
        <div class="block" v-show="true">
          <ButtonSolidBox
            class="float-right py-2 ml-8 mb-5"
            color="secondary"
            @click="$router.push({ name: 'route.dashboard.newsletter.editor.create' })"
            v-show="true"
          >
            Update Current Newsletter
          </ButtonSolidBox>
        </div>
        <!-- <ListMenuBar
          search-placeholder-label="Search article"
          main-context-button-label="New Article"
          @search="(val) => search(val)"
          @search-reset="() => reset()"
          @filter-changed="(val) => filterChanged(val)"
          style-dropdown-content="min-width:210px"
          :filter="true"
          :filter-data="filter"
          :main-context-button-target="{
            name: 'route.dashboard.article.editor.create'
          }"
          use-main-context-button
        ></ListMenuBar> -->
        <div class="my-2 mx-auto">
          <loading v-show="!NEWSLETTERS_READY" />
        </div>
      </div>
      <div class="my-10 mx-auto px-20 py-10">
        <div class="mb-4" v-if="NEWSLETTERS_READY && GET_NEWSLETTERS.length < 1"><strong>No newsletter available.</strong></div>
        <div class="shadow my-5 rounded bg-gray-100 p-5" v-for="record in GET_NEWSLETTERS" :key="record.id">
          <div class="w-full">
            <strong>{{ record.subject ? record.subject : '-' }}</strong>
          </div>
          <div class="w-full">
            <small>{{ months[record.month_period-1] }} {{ record.year_period }}</small>
          </div>
        </div>
      </div>
    </div>
    <!-- <TheSideCalendarBar :range="range" hide-today @date-updated="(val) => dateRangeUpdated(val)" :show-button-toggle="true" :date-default-state="dateState" @date-state-changed="(val) => dateFilterState(val)" /> -->
  </div>
</template>

<script>
// import ListMenuBar from '@/components/List/ListMenuBar.vue'
// import TheSideCalendarBar from '@/components/TheSideCalendarBar.vue'
// import SimplePagination from '@/components/Paginate/SimplePagination.vue'
import Loading from '@/components/Loading.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import { createNamespacedHelpers } from 'vuex'
const newsletter = createNamespacedHelpers('newsletter')
// const article = createNamespacedHelpers('article')

export default {
  components: {
    // ListMenuBar: ListMenuBar,
    ButtonSolidBox,
    Loading,
    TheSideNavigationBar,
    TheContentTopNavigationBar
    // TheSideCalendarBar,
    // SimplePagination
  },
  computed: {
    ...newsletter.mapGetters(['GET_NEWSLETTERS', 'NEWSLETTERS_READY'])
  },
  methods: {
    ...newsletter.mapActions(['getNewsletterHistories'])
  },
  created () {
  },
  mounted () {
    this.getNewsletterHistories()
  },
  data () {
    return {
      page: 1,
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Des']
    }
  }
}
</script>
<style scoped>
@media (min-width: 1440px) {
  #mainContent{
    padding-right:395px;
  }
}
</style>
