<template>
  <div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8">
        <div class="px-8 py-3" v-if="!PROFILE_STATE">
          <Loading show />
        </div>
        <div class="px-8 py-3 my-8 bg-white rounded" v-if="PROFILE_STATE">
          <div class="blocked mt-10">
            <ButtonSolidBox v-show="ARTICLE_READY" class="px-8 py-1 mb-5 rounded-full mt-5" @click="showChangePassModal = true">
              Change password
            </ButtonSolidBox>
          </div>
          <span class="font-subtitle-2 text-gray-500 mt-6">User detail</span>
          <br>
          <span style="font-size:24pt">{{ GET_PROFILE.fullname }}</span>
          <br>
          <span><small>Joined date - {{ moment(GET_PROFILE.created_at).format('/DD/MM/YYYY') }}</small></span>
          <hr class="border-gray-500 my-2">
          <Modal classes="overflow-y-auto" name="ChangePass" :show.sync="showChangePassModal" strict-close>
            <div
              class="py-3 mx-auto max-w-lg"
            >
              <div class="lg:mx-12 mx-2 pb-6 py-3">
                <p class="font-headline-6 text-headline-6 p-3 mx-6">
                  Change password
                </p>
              </div>
              <div class="lg:mx-12 mx-2 p-3">
                <!-- <InputTextBox class="px-6" type="email" placeholder="Email" /> -->
                <InputTextBox
                  class="px-0 py-2 mx-1"
                  type="password"
                  placeholder="Enter your current password"
                  @input="val => setOldPassword(val)"
                  :hide-errors="!changePassWarning.password"
                  :disabled="processing"
                  warning="Please check again"
                  warning-class="text-red-500"
                />
              </div>
              <div class="lg:mx-12 mx-2 px-3 pb-3">
                <!-- <InputTextBox class="px-6" type="email" placeholder="Email" /> -->
                <InputTextBox
                  class="px-0 py-0 mx-1"
                  type="password"
                  placeholder="Enter your new password"
                  @input="val => setNewPassword(val)"
                  :hide-errors="!changePassWarning.new_password"
                  :disabled="processing"
                  warning="Please check again"
                  warning-class="text-red-500"
                />
              </div>
              <div class="lg:mx-12 mx-2 px-3">
                <Loading :show="processing" />
              </div>
              <div class="lg:mx-12 mx-2 px-6">
                <small>{{changePassMessage}}</small>
              </div>
              <div class="lg:mx-12 mx-2 px-3">
                <div class="p-3 mx-3">
                  <ButtonSolidBox
                    class="w-full h-full"
                    color="primary"
                    @click="changePassword()"
                  >
                    Change now.
                  </ButtonSolidBox>
                  <ButtonSolidBox
                    class="w-full h-full py-1 mt-2"
                    color="gray"
                    @click="showChangePassModal = false"
                  >
                    Close.
                  </ButtonSolidBox>
                </div>
              </div>
            </div>
          </Modal>
          <Modal name="Article" :show.sync="showModal"  strict-close>
            <div class="flex flex-col align-items-center w-full p-12">
              <div class="mx-auto my-8 text-center">
                <svg
                  class="block mx-auto"
                  width="64"
                  height="64"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                    fill="white"
                    fill-opacity="0.12"
                  />
                  <path
                    d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                    fill="#48D5D1"
                    fill-opacity="0.12"
                  />
                  <path
                    d="M63.25 32C63.25 49.2589 49.2589 63.25 32 63.25C14.7411 63.25 0.75 49.2589 0.75 32C0.75 14.7411 14.7411 0.75 32 0.75C49.2589 0.75 63.25 14.7411 63.25 32Z"
                    stroke="#48D5D1"
                    stroke-width="1.5"
                  />
                  <path
                    d="M46.334 26.0133L28.706 43.6414L17.666 32.6014L23.028 27.2395L28.706 32.9174L32 29.6234L40.972 20.6514L46.334 26.0133Z"
                    fill="#48D5D1"
                  />
                </svg>
                <div class="block mt-12 font-headline-6 text-headline-6 text-gray">
                  Article has been saved!
                </div>
                <div
                  class="block font-body-1 text-body-1 text-gray-500 max-w-sm mb-5"
                >
                  Article has been saved successfully.
                </div>
                <ButtonSolidBox v-show="ARTICLE_READY" class="px-8" @click="closeModal()">
                  Ok
                </ButtonSolidBox>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
    <!-- <div class="w-104 min-w-0 bg-white" style="min-width:395px;max-width:395px">
      <div class="px-12 mx-auto mt-12 flex justify-between items-center">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="font-headline-6 text-headline-6">Article categories</h3>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<style scoped>
 </style>

<script>
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
// import InputDropdown from '@/components/Inputs/InputDropdown.vue'
// import InputImage from '@/components/Inputs/InputImage.vue'
// import InputAdvancedTextArea from '@/components/Inputs/InputAdvancedTextArea.vue'
// import Alert from '@/components/Alert.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import Modal from '@/components/Modal.vue'
import Loading from '@/components/Loading.vue'
import { createNamespacedHelpers } from 'vuex'
import request from '@/utils/axios'

const category = createNamespacedHelpers('category')
const article = createNamespacedHelpers('article')
const user = createNamespacedHelpers('user')

export default {
  components: {
    InputTextBox,
    // InputDropdown,
    // InputImage,
    // Alert,
    Modal,
    Loading,
    // InputAdvancedTextArea,
    ButtonSolidBox,
    TheContentTopNavigationBar: TheContentTopNavigationBar,
    // TheSideCalendarBar: TheSideCalendarBar,
    TheSideNavigationBar: TheSideNavigationBar
  },
  data () {
    return {
      showModal: false,
      storeError: false,
      changePassWarning: {
        password: false,
        new_password: false
      },
      changePass: {
        password: '',
        new_password: ''
      },
      changePassMessage: '',
      showChangePassModal: false,
      processing: false,
      form: {
        article_title: '',
        thumbnail: '',
        published_at: '',
        article_category_id: '',
        first_summary: '',
        second_summary: '',
        third_summary: '',
        first_content: '',
        second_content: ''
      },
      warning: {
        article_title: '',
        published_at: '',
        thumbnail: '',
        article_category_id: '',
        first_summary: '',
        second_summary: '',
        third_summary: '',
        first_content: '',
        second_content: ''
      }
    }
  },
  watch: {
    'form.first_content': function (newVal, oldVal) {
    // console.log(newVal)
    }
  },
  computed: {
    ...category.mapGetters(['SELECT_CATEGORY']),
    ...article.mapGetters(['ARTICLE_READY']),
    ...user.mapGetters(['GET_PROFILE', 'PROFILE_STATE'])
  },
  methods: {
    ...category.mapActions(['getAllCategories']),
    ...article.mapActions(['addArticle', 'createInit']),
    ...user.mapActions(['getProfile']),
    setOldPassword (val) {
      this.changePass.password = val
    },
    setNewPassword (val) {
      this.changePass.new_password = val
    },
    getSubmitButtonLabel () {
      if (
        Object.prototype.hasOwnProperty.call(this.$route.params, 'slug') &&
        `${this.$route.params.slug}`.length > 0
      ) {
        return 'Update'
      }
      return 'Save'
    },
    changePassword () {
      var validate = true
      Object.keys(this.changePass).forEach(key => {
        if (this.changePass[key] === '') {
          this.changePassWarning[key] = true
          validate = false
        } else {
          this.changePassWarning[key] = false
        }
      })

      if (validate) {
        if (this.changePass.password === this.changePass.new_password) {
          this.changePassMessage = 'Old password cannot be similar to new password.'
          return
        }
      } else {
        return
      }

      this.processing = true
      request().post('/v1/internal-user/change-password', this.changePass)
        .then(async (result) => {
          if (result.data.data === 'ok') {
            this.changePassMessage = 'Password successfully changed. Will close in 3 seconds'
            this.changePass.password = ''
            this.changePass.new_password = ''
            setTimeout(() => {
              this.showChangePassModal = false
            }, 3000)
          } else {
            this.changePassMessage = this.result.data.error
          }
          this.processing = false
        })
        .catch((_) => {
          // console.log(err)
          this.isRegisterFailed = true
          this.changePassMessage = 'Something wrong happened.'
          this.processing = false
        })
    },
    fileAdded (file) {
      this.form.thumbnail = file
      this.warning.thumbnail = ''
    },
    fileRemoved () {
      this.form.thumbnail = ''
      this.warning.thumbnail = 'This field is required'
    },
    processValue (value, target) {
      if (value === '') {
        this.warning[target] = 'This field is required'
      } else {
        if (this.warning[target] !== '') {
          this.warning[target] = ''
        }
        if (target === 'article_category_id') {
          this.form[target] = value.id
          return
        }
        if (target === 'published_at') {
          this.form[target] = this.moment(value).format('YYYY-MM-DD')
          return
        }
        this.form[target] = value
      }
    },
    validate () {
      var validation = true
      Object.keys(this.form).forEach(val => {
        if (this.form[val] === '') {
          validation = false
          this.warning[val] = 'This field is required'
        }
      })
      return validation
    },
    submitForm () {
      if (this.validate()) {
        this.commit()
      }
    },
    commit () {
      this.storeError = false
      var formData = new FormData()
      Object.keys(this.form).forEach(val => {
        formData.append(val, this.form[val])
      })
      this.addArticle({ form: formData }).then((data) => {
        if (data.data !== null) {
          this.form = data.data
          this.showModal = true
        }
      }).catch(err => {
      // console.log(err)
        if (err.response !== undefined && err.response.status === 422) {
          err.response.data.data.forEach(val => {
            this.warning[val.path] = val.message
          })
        }
        this.storeError = true
      })
    },
    closeModal () {
      this.showModal = false
      this.showDetail(this.form.id)
    },
    showDetail (id) {
      this.$router.push({ name: 'route.dashboard.article.editor.existing', params: { id: id } })
    }
  },
  created () {
    this.createInit()
    this.getAllCategories()
    this.getProfile().then(() => {
    // console.log('your profile')
    // console.log(this.GET_PROFILE)
    })
  }
}
</script>
