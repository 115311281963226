<template>
<div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8">
        <ListMenuBar
          search-placeholder-label="Search slider"
          main-context-button-label="New slider"
          @search="(val) => search(val)"
          @search-reset="() => fetchData()"
          @filter-changed="(val) => filterChanged(val)"
          style-dropdown-content="min-width:210px"
          :filter="true"
          :filter-data="filter"
          :main-context-button-target="{
            name: 'route.dashboard.articleslider.editor.create'
          }"
          use-main-context-button
        ></ListMenuBar>
        <div class="my-2 mx-auto">
          <loading v-show="!ARTICLE_SLIDER_READY" />
          <div class="block text-gray-500" v-show="ARTICLE_SLIDER_READY && GET_ARTICLE_SLIDERS.length == 0">
            <small>No slider found at this time.</small>
          </div>
          <div
            v-show="ARTICLE_SLIDER_READY"
            v-for="item in GET_ARTICLE_SLIDERS"
            :key="'article-'+item.id"
            class="flex flex-wrap items-stretch px-12 py-10 my-6 cursor-pointer bg-white rounded"
            @click="
              $router.push({
                name: 'route.dashboard.articleslider.editor.existing',
                params: { id: item.id }
              })
            "
          >
            <div class="w-full flex flex-col items-start">
              <div class="block">
                <!-- <span class="font-subtitle-2 text-subtitle-2 inline-block pr-4">
                  {{ item.slider_name }}
                </span> -->
                <span
                  class="font-subtitle-1 text-subtitle-2 inline-block text-gray-500"
                  ><small>created at: </small>{{ getDate(item.created_at) }}</span
                >
              </div>
              <h4
                class="font-headline-5 text-headline-5 title-font text-gray-900 mt-2 mb-2"
              >
                <small>{{ item.slider_name }}</small>
              </h4>
              <!-- <p class="text-gray-500">
                <small>{{ item.category.article_category_name }}</small>
              </p> -->
              <!-- <p class="font-subtitle-1 text-subtitle-1 text-gray-500">
                {{ item.metadata.length }} article included.
              </p> -->
              <div class="my-2 w-full" v-if="item.metadata.length>0">
                <div class="grid grid-cols-3 gap-4">
                  <div class="bg-background-50 rounded shadow px-3 py-1" v-for="(metaItem) in item.metadata" v-bind:key="metaItem.id">
                    <small>{{ metaItem.article.article_title }}</small>
                    </div>
                </div>
              </div>
            </div>
            <!-- <div class="p-3 w-1/3 flex">
              <img :src="getImage(item.thumbnail)" width="150" height="85" style="object-fit:contain;height:85px;width:150px" />
            </div> -->
          </div>
          <SimplePagination v-show="ARTICLE_SLIDER_READY" :min="1" :max="GET_ARTICLE_SLIDER_TOTAL_PAGE" :page="fetchParam.currentPage" @move="(step) => movePage(step)" />
        </div>
      </div>
    </div>
    <!-- <TheSideCalendarBar :range="range" hide-today @date-updated="(val) => dateRangeUpdated(val)" /> -->
  </div>
</template>

<style scoped>
 </style>

<script>
import ListMenuBar from '@/components/List/ListMenuBar.vue'
import Loading from '@/components/Loading.vue'
// import TheSideCalendarBar from '@/components/TheSideCalendarBar.vue'
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import SimplePagination from '@/components/Paginate/SimplePagination.vue'
import { createNamespacedHelpers } from 'vuex'

const articleSlider = createNamespacedHelpers('articleSlider')

export default {
  components: {
    ListMenuBar: ListMenuBar,
    Loading,
    TheSideNavigationBar,
    // TheSideCalendarBar,
    TheContentTopNavigationBar,
    SimplePagination
  },
  computed: {
    ...articleSlider.mapGetters(['ARTICLE_SLIDER_READY', 'GET_ARTICLE_SLIDERS', 'GET_ARTICLE_SLIDER_TOTAL_PAGE'])
  },
  methods: {
    ...articleSlider.mapActions(['getSliders', 'findSliders']),
    dateRangeUpdated (val) {
      this.fetchParam.range.start = this.moment(val.start).format('YYYY-MM-DD')
      this.fetchParam.range.end = this.moment(val.end).format('YYYY-MM-DD')
      this.fetchData()
    },
    filterChanged (val) {
      this.fetchParam.filter = val
      this.fetchData()
    },
    getDate (dateString) {
      return this.moment(dateString).format('D/MM/YYYY')
    },
    getImage (path) {
      var newPath = path.replace('public/', '')
      return process.env.VUE_APP_API_URL + '/' + newPath
    },
    search (val) {
      var data = { find: val }
      this.findSliders({ data: data })
    },
    reset () {
      this.getSliders({ data: null }).catch((err) => {
        if (err.response.status !== undefined && err.response.status === 401) {
          localStorage.removeItem('access_token')
          this.$router.push({ name: 'route.root' })
        }
      })
    },
    movePage (step) {
      this.fetchParam.currentPage += step
      // console.log(this.fetchParam.currentPage)
      this.fetchData()
    },
    fetchData () {
      this.getSliders({ data: { range: this.fetchParam.range, page: this.fetchParam.currentPage, filter: this.fetchParam.filter } })
    }
  },
  created () {
    this.range.start = this.moment().startOf('month').format('YYYY-MM-DD')
    this.range.end = this.moment().endOf('month').format('YYYY-MM-DD')
    this.fetchParam.start = this.range.start
    this.fetchParam.end = this.range.end
    this.fetchData()
  },
  data () {
    return {
      posts: [1, 2, 3],
      range: {
        start: '',
        end: ''
      },
      fetchParam: {
        filter: {
          target: 'created_at',
          sort: 'asc'
        },
        currentPage: 1,
        range: {
          start: '',
          end: ''
        }
      },
      filter: [
        { label: 'Create date', target: 'created_at', sort: 'asc', checked: true },
        { label: 'Create date', target: 'created_at', sort: 'desc', checked: false },
        { label: 'Title', target: 'slider_name', sort: 'asc', checked: false },
        { label: 'Title', target: 'slider_name', sort: 'desc', checked: false }
      ]
    }
  }
}
</script>
