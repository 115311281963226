<template>
  <div class="mt-12 mb-8 flex flex-row gap-x-3">
    <InputTextBox
      type="search"
      class="inline-block align-middle flex-grow"
      :model="searchValue"
      :placeholder="searchPlaceholderLabel"
      @find="(val) => search(val)"
      @input="searchInput"
    ></InputTextBox>
    <div class="flex-0 inline-block align-middle" v-if="filter">
      <div class="float-right">
        <ButtonDropdown hold-on-click :style-content="styleDropdownContent">
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="48" height="48" rx="4" fill="white" />
            <path
              d="M22.3301 28.5929H16.0294"
              stroke="#212121"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M25.1405 18.9004H31.4412"
              stroke="#212121"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.7263 18.8463C20.7263 17.5506 19.6681 16.5 18.3631 16.5C17.0582 16.5 16 17.5506 16 18.8463C16 20.1419 17.0582 21.1925 18.3631 21.1925C19.6681 21.1925 20.7263 20.1419 20.7263 18.8463Z"
              stroke="#212121"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M32 28.5538C32 27.2581 30.9426 26.2075 29.6376 26.2075C28.3318 26.2075 27.2737 27.2581 27.2737 28.5538C27.2737 29.8494 28.3318 30.9 29.6376 30.9C30.9426 30.9 32 29.8494 32 28.5538Z"
              stroke="#212121"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <template v-slot:content>
            <!-- <MenuDropdownItem>
              <InputRadioOption name="sortByColumn">
                Publish Date
              </InputRadioOption>
            </MenuDropdownItem> -->
            <!-- <MenuDropdownItem>
              <InputRadioOption name="sortByColumn">
                Create date <small>ASC</small>
              </InputRadioOption>
            </MenuDropdownItem> -->

            <MenuDropdownItem v-for="data in filterData" :key="'dropdownItem-'+data.target+'-'+data.sort">
              <InputRadioOption name="sortByColumn" @update="filterIt(data.target,data.sort)" :is-checked="data.checked">
                  <svg v-if="data.sort === 'asc'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 301.219 301.219" width="12" height="12" style="margin-left:5px;margin-right:0px;padding-right:0px;display:inline;" preserveAspectRatio="none">
                    <g fill="gray">
                      <path d="M149.365,262.736H10c-5.523,0-10,4.477-10,10v10c0,5.523,4.477,10,10,10h139.365c5.522,0,10-4.477,10-10v-10
                        C159.365,267.213,154.888,262.736,149.365,262.736z"/>
                      <path d="M10,229.736h120.586c5.522,0,10-4.477,10-10v-10c0-5.523-4.478-10-10-10H10c-5.523,0-10,4.477-10,10v10
                        C0,225.259,4.477,229.736,10,229.736z"/>
                      <path d="M10,166.736h101.805c5.522,0,10-4.477,10-10v-10c0-5.523-4.478-10-10-10H10c-5.523,0-10,4.477-10,10v10
                        C0,162.259,4.477,166.736,10,166.736z"/>
                      <path d="M10,96.736h83.025c5.522,0,10-4.477,10-10v-10c0-5.523-4.478-10-10-10H10c-5.523,0-10,4.477-10,10v10
                        C0,92.259,4.477,96.736,10,96.736z"/>
                      <path d="M10,33.736h64.244c5.522,0,10-4.477,10-10v-10c0-5.523-4.478-10-10-10H10c-5.523,0-10,4.477-10,10v10
                        C0,29.259,4.477,33.736,10,33.736z"/>
                      <path d="M298.29,216.877l-7.07-7.071c-1.875-1.875-4.419-2.929-7.071-2.929c-2.652,0-5.195,1.054-7.071,2.929l-34.394,34.393
                        V18.736c0-5.523-4.477-10-10-10h-10c-5.522,0-10,4.477-10,10v225.462l-34.394-34.393c-1.876-1.876-4.419-2.929-7.071-2.929
                        c-2.652,0-5.196,1.054-7.071,2.929l-7.07,7.071c-3.905,3.905-3.905,10.237,0,14.142l63.535,63.536
                        c1.876,1.875,4.419,2.929,7.071,2.929c2.652,0,5.195-1.054,7.071-2.929l63.535-63.536
                        C302.195,227.113,302.195,220.782,298.29,216.877z"/>
                    </g>
                  </svg>
                  <svg v-else version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 301.219 301.219" width="12" height="12" style="margin-left:5px;margin-right:0px;padding-right:0px;display:inline;" preserveAspectRatio="none">
                    <g fill="gray">
                      <path d="M159.365,23.736v-10c0-5.523-4.477-10-10-10H10c-5.523,0-10,4.477-10,10v10c0,5.523,4.477,10,10,10h139.365
                        C154.888,33.736,159.365,29.259,159.365,23.736z"/>
                      <path d="M130.586,66.736H10c-5.523,0-10,4.477-10,10v10c0,5.523,4.477,10,10,10h120.586c5.523,0,10-4.477,10-10v-10
                        C140.586,71.213,136.109,66.736,130.586,66.736z"/>
                      <path d="M111.805,129.736H10c-5.523,0-10,4.477-10,10v10c0,5.523,4.477,10,10,10h101.805c5.523,0,10-4.477,10-10v-10
                        C121.805,134.213,117.328,129.736,111.805,129.736z"/>
                      <path d="M93.025,199.736H10c-5.523,0-10,4.477-10,10v10c0,5.523,4.477,10,10,10h83.025c5.522,0,10-4.477,10-10v-10
                        C103.025,204.213,98.548,199.736,93.025,199.736z"/>
                      <path d="M74.244,262.736H10c-5.523,0-10,4.477-10,10v10c0,5.523,4.477,10,10,10h64.244c5.522,0,10-4.477,10-10v-10
                        C84.244,267.213,79.767,262.736,74.244,262.736z"/>
                      <path d="M298.29,216.877l-7.071-7.071c-1.875-1.875-4.419-2.929-7.071-2.929c-2.652,0-5.196,1.054-7.072,2.929l-34.393,34.393
                        V18.736c0-5.523-4.477-10-10-10h-10c-5.523,0-10,4.477-10,10v225.462l-34.393-34.393c-1.876-1.875-4.419-2.929-7.071-2.929
                        c-2.652,0-5.196,1.054-7.071,2.929l-7.072,7.071c-3.904,3.905-3.904,10.237,0,14.142l63.536,63.536
                        c1.953,1.953,4.512,2.929,7.071,2.929c2.559,0,5.119-0.976,7.071-2.929l63.536-63.536
                        C302.195,227.113,302.195,220.781,298.29,216.877z"/>
                    </g>
                  </svg>
                {{ data.label }}
              </InputRadioOption>
            </MenuDropdownItem>
          </template>
        </ButtonDropdown>
      </div>
    </div>
    <div v-if="useMainContextButton" class="w-3/12 inline-block align-middle">
      <ButtonSolidBox
        class="w-full"
         color="secondary"
        @click="
          mainContextButtonTarget
            ? $router.push(mainContextButtonTarget)
            : () => {}
        "
      >
        {{ mainContextButtonLabel || 'Add' }}
      </ButtonSolidBox>
    </div>
  </div>
</template>

<script>
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import InputRadioOption from '@/components/Inputs/InputRadioOption.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import ButtonDropdown from '@/components/Buttons/ButtonDropdown.vue'
import MenuDropdownItem from '@/components/Menu/MenuDropdownItem.vue'

export default {
  components: {
    ButtonDropdown,
    MenuDropdownItem,
    InputTextBox,
    ButtonSolidBox,
    InputRadioOption
  },
  props: {
    mainContextButtonTarget: {
      type: Object
    },
    searchPlaceholderLabel: {
      type: String
    },
    styleDropdownContent: {
      type: String,
      default: ''
    },
    mainContextButtonLabel: {
      type: String
    },
    useMainContextButton: {
      type: Boolean,
      default () {
        return false
      }
    },
    filter: {
      type: Boolean,
      default: false
    },
    filterData: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data: () => {
    return {
      searchValue: ''
    }
  },
  methods: {
    search (value) {
      if (value !== '') {
      // console.log('instructing to find user')
        this.$emit('search', value)
      }
    },
    searchInput (val) {
      if (val === '') {
        this.$emit('search-reset')
      }
    },
    filterIt (target, sort) {
      this.$emit('filter-changed', { target: target, sort: sort })
    }
  }
}
</script>
