<template>
  <div class="h-full">
    <div class="border border-gray-100 bg-background-50 rounded">
      <textarea
        name=""
        id=""
        rows="6"
        :placeholder="placeholder"
        class="px-4 py-3 font-body-1 text-body-1 bg-transparent focus:outline-none focus:rang placeholder-gray-500 w-full"
        :value="value"
        :disabled="disabled"
        @input="updateInput($event)"
      ></textarea>
    </div>
    <div class="font-caption text-caption px-4 py-2" :class="warningClass">
      {{warning}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    placeholder: {
      type: String,
      default: () => {
        return ''
      }
    },
    warning: {
      type: String,
      default: () => {
        return ''
      }
    },
    warningClass: {
      type: String,
      default: () => {
        return 'text-gray-500'
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  methods: {
    updateInput (el) {
      this.$emit('input', el.target.value)
    }
  }
}
</script>
