<template>
  <div class="flex justify-center items-center mt-3">
    <div v-show="min < page">
      <button class="bg-gray-100 text-gray-800 px-6 py-1 mr-2 rounded-xl" @click="move(-1)"> <small>prev</small> </button>
    </div>
    <div>
      <button v-show="max > page" class="bg-gray-100 text-gray-800 px-6 py-1 ml-2 rounded-xl" @click="move(1)"> <small>next</small> </button>
    </div>
  </div>
</template>
<script>
export default ({
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 0
    }
  },
  methods: {
    move (step) {
      this.$emit('move', step)
    }
  }
})
</script>
