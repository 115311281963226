<template>
<div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8">
        <ListMenuBar
          search-placeholder-label="Search article"
          main-context-button-label="New Article"
          @search="(val) => search(val)"
          @search-reset="() => reset()"
          @filter-changed="(val) => filterChanged(val)"
          style-dropdown-content="min-width:210px"
          :filter="true"
          :filter-data="filter"
          :main-context-button-target="{
            name: 'route.dashboard.article.editor.create'
          }"
          use-main-context-button
        ></ListMenuBar>
        <div class="my-2 mx-auto">
          <loading v-show="!ARTICLE_READY" />
          <div class="block text-gray-500" v-show="ARTICLE_READY && GET_ARTICLES.length == 0">
            <small>No article found at this time.</small>
          </div>
          <div
            v-show="ARTICLE_READY"
            v-for="item in GET_ARTICLES"
            :key="'article-'+item.id"
            class="flex flex-wrap items-stretch px-12 py-10 my-6 cursor-pointer bg-white rounded"
            @click="
              $router.push({
                name: 'route.dashboard.article.editor.existing',
                params: { id: item.id }
              })
            "
          >
            <div class=" w-2/3 flex flex-col items-start">
              <div class="block">
                <span class="font-subtitle-2 text-subtitle-2 inline-block pr-4">
                  {{ item.writter.fullname }}
                </span>
                <span
                  class="font-subtitle-1 text-subtitle-2 inline-block pl-4 text-gray-500"
                  > - <small>created at: </small>{{ getDate(item.created_at) }}</span
                >
              </div>
              <div class="block">
                <span
                  class="font-subtitle-1 text-subtitle-2 inline-block pl-4 text-gray-500"
                  ><small>Viewer : </small>{{ item.article_viewer + item.guest_viewer }}</span
                >
              </div>
              <h4
                class="font-headline-5 text-headline-5 title-font text-gray-900 mt-4 mb-2"
              >
                <small>{{ item.article_title }}</small>
              </h4>
              <p class="text-gray-500">
                <small>{{ item.category.article_category_name }}</small>
              </p>
              <p class="font-subtitle-1 text-subtitle-1 text-gray-500">
                {{ item.first_summary }}
              </p>
              <p class="font-subtitle-1 text-subtitle-1 text-gray-500">
                <small>Publish date: {{ item.published_at ? getDate(item.published_at) : getDate(item.created_at) }}</small>
              </p>
            </div>
            <div class="p-3 w-1/3 flex">
              <img :src="getImage(item.thumbnail)" width="150" height="85" style="object-fit:contain;height:85px;width:150px" />
            </div>
          </div>
          <SimplePagination v-show="ARTICLE_READY" :min="1" :max="GET_ARTICLE_TOTAL_PAGE" :page="fetchParam.currentPage" @move="(step) => movePage(step)" />
        </div>
      </div>
    </div>
    <TheSideCalendarBar :range="range" hide-today @date-updated="(val) => dateRangeUpdated(val)" :show-button-toggle="true" :date-default-state="dateState" @date-state-changed="(val) => dateFilterState(val)" />
  </div>
</template>

<script>
import ListMenuBar from '@/components/List/ListMenuBar.vue'
import Loading from '@/components/Loading.vue'
import TheSideCalendarBar from '@/components/TheSideCalendarBar.vue'
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import SimplePagination from '@/components/Paginate/SimplePagination.vue'
import { createNamespacedHelpers } from 'vuex'

const article = createNamespacedHelpers('article')

export default {
  components: {
    ListMenuBar: ListMenuBar,
    Loading,
    TheSideNavigationBar,
    TheSideCalendarBar,
    TheContentTopNavigationBar,
    SimplePagination
  },
  computed: {
    ...article.mapGetters(['ARTICLE_READY', 'GET_ARTICLES', 'GET_ARTICLE_TOTAL_PAGE'])
  },
  methods: {
    ...article.mapActions(['getArticles', 'findArticles']),
    dateRangeUpdated (val) {
      this.fetchParam.range.start = this.moment(val.start).format('YYYY-MM-DD')
      this.fetchParam.range.end = this.moment(val.end).format('YYYY-MM-DD')
      this.fetchData()
    },
    filterChanged (val) {
      this.fetchParam.filter = val
      this.fetchData()
    },
    dateFilterState (val) {
      // console.log(this.fetchParam.query)
      this.dateState = !this.dateState
      this.fetchParam.query = !this.dateState ? 'nodate' : ''
      if (!this.dateState) {
        this.fetchData()
      }
    },
    getDate (dateString) {
      return this.moment(dateString).format('D/MM/YYYY')
    },
    getImage (path) {
      // var newPath = path.replace('public/', '')
      // console.log('image url: ' + process.env.VUE_APP_IMAGE_URL)
      return process.env.VUE_APP_IMAGE_URL + '/' + path
    },
    search (val) {
      var data = { find: val }
      this.findArticles({ data: data })
    },
    reset () {
      this.getArticles({ data: null }).catch((err) => {
        if (err.response !== undefined && err.response.status === 401) {
          localStorage.removeItem('access_token')
          this.$router.push({ name: 'route.root' })
        } else if (err.response !== undefined && err.response.status === 403) {
          this.$router.push({ name: 'route.dashboard.home' })
        }
      })
    },
    movePage (step) {
      this.fetchParam.currentPage += step
      // console.log(this.fetchParam.currentPage)
      this.fetchData()
    },
    fetchData () {
      this.getArticles({ data: { range: this.fetchParam.range, page: this.fetchParam.currentPage, filter: this.fetchParam.filter, query: this.fetchParam.query } })
    }
  },
  created () {
    this.range.start = this.moment().startOf('month').format('YYYY-MM-DD')
    this.range.end = this.moment().endOf('month').format('YYYY-MM-DD')
    this.fetchParam.start = this.range.start
    this.fetchParam.end = this.range.end
    this.fetchData()
  },
  data () {
    return {
      posts: [1, 2, 3],
      range: {
        start: '',
        end: ''
      },
      dateState: false,
      fetchParam: {
        filter: {
          target: 'created_at',
          sort: 'desc'
        },
        query: 'nodate',
        currentPage: 1,
        range: {
          start: '',
          end: ''
        }
      },
      filter: [
        { label: 'Create date', target: 'created_at', sort: 'asc', checked: false },
        { label: 'Create date', target: 'created_at', sort: 'desc', checked: true },
        { label: 'Title', target: 'article_title', sort: 'asc', checked: false },
        { label: 'Title', target: 'article_title', sort: 'desc', checked: false }
      ]
    }
  }
}
</script>
