<template>
  <div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />

      <div class="my-10 mx-auto px-20 py-10">
        <div class="mb-4"><strong>Articles currently published</strong></div>

        <div v-for="item in GET_BLASTDATA" :key="item.id" @click="viewDetail(item.id)" class="shadow my-5 rounded p-5" :class="[pushState(item) ? 'bg-gray-100' : 'bg-gray-300' ]">
          <div class="w-full">
            <strong>{{ item.article_title }}</strong>
          </div>
          <div class="w-full">
            <small>{{ pushLabel(item) }}</small>
          </div>
        </div>

        <!-- <div class="shadow my-5 rounded bg-gray-300 p-5">
          <div class="w-full">
            <strong>Title Algo Research</strong>
          </div>
          <div class="w-full">
            <small>16 November 2023</small>
          </div>
        </div>

        <div class="shadow my-5 rounded bg-gray-100 p-5">
          <div class="w-full">
            <strong>Title Algo Research</strong>
          </div>
          <div class="w-full">
            <small>16 November 2023</small>
          </div>
        </div>

        <div class="shadow my-5 rounded bg-gray-300 p-5">
          <div class="w-full">
            <strong>Title Algo Research</strong>
          </div>
          <div class="w-full">
            <small>16 November 2023</small>
          </div>
        </div> -->

      </div>

    </div>
  </div>
</template>
<style scoped>
@media (min-width: 1440px) {

}</style>
<script>
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import { createNamespacedHelpers } from 'vuex'

const articleBlast = createNamespacedHelpers('articleBlast')

export default {
  components: {
    TheSideNavigationBar,
    TheContentTopNavigationBar
  },
  computed: {
    ...articleBlast.mapGetters(['BLASTDATA_READY', 'GET_BLASTDATA'])
  },
  methods: {
    ...articleBlast.mapActions(['fetchBlastData']),
    pushLabel (item) {
      if (item.mail_blast == null) {
        return 'ready to blast'
      }
      if (item.mail_blast.subject !== null) {
        return 'email sent'
      }
      if (item.mail_blast.subject == null) {
        return 'ready to blast'
      }
    },
    pushState (item) {
      if (item.mail_blast == null) {
        return true
      }
      if (item.mail_blast.subject !== null) {
        return false
      }
      if (item.mail_blast.subject == null) {
        return true
      }
    },
    viewDetail (contentId) {
      this.$router.push({
        name: 'route.dashboard.articleblast.update',
        params: { id: contentId }
      })
    }
  },
  created () {
    this.fetchBlastData()
  }
}
</script>
