<template>
  <label class="radio-option inline-flex items-center cursor-pointer">
    <input
      type="radio"
      :name="name"
      class="h-5 w-5"
      value="1"
      @click="$emit('update')"
      :checked="isChecked"
    />
    <span class="ml-2 text-gray-500">
      <slot />
    </span>
  </label>
</template>

<script>
export default {
  props: {
    name: {
      type: String
    },
    isChecked: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.radio-option >>> input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  @apply border border-solid border-gray-500;
}
.radio-option >>> input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
.radio-option >>> input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
.radio-option >>> input[type='radio']:checked {
  @apply border-orange-accent;
}
.radio-option >>> input[type='radio']:checked + span {
  @apply border-orange-accent text-gray;
}
.radio-option >>> input[type='radio']:checked:before {
  @apply bg-orange-accent;
}</style>
