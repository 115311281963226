<template>
  <div class="flex flex-row overflow-auto w-full">
    <TheSideNavigationBar />
    <div class="flex-auto flex-grow flex-shrink bg-background-50" id="mainContent">
      <TheContentTopNavigationBar />
      <div class="w-full px-8">
        <!-- <div class="block" v-show="true">
          <ButtonSolidBox
            class="float-right py-2 ml-8 mb-5"
            color="red"
            @click="$router.push({ name: 'route.dashboard.newsletterindex' })"
            v-show="true"
          >
            Cancel
          </ButtonSolidBox>
        </div> -->
        <div class="my-2 mx-auto">
          <loading v-show="!NEWSLETTER_READY" />
        </div>
        <div class="my-2 mx-auto">
            <Loading :show="GET_UPDATE_STATE" />
            <Alert relative normal v-show="showSuccess" type="success">Newsletter content is updated!</Alert>
        </div>
        <div class="flex flex-col w-full" v-show="!GET_UPDATE_STATE && hasSent()">
          <div class="mt-5"><strong>Newsletter Distributed [READ ONLY]</strong></div>
        </div>
        <div class="flex flex-col w-full border rounded p-5" v-show="!GET_UPDATE_STATE && !hasSent()">
          <div class="mb-4"><strong>Main Content [TOP SECTION]:</strong></div>
          <h5 class="font-subtitle-2 text-subtitle-1 mt-6 mb-1 text-gray-600"><small>Type minimum 3 words to search</small></h5>
          <InputTextBox
            placeholder="Article title"
            @input="val => processValue(val,'article_title')"
            :warning="warning.article_title"
            :value="article_title"
            warningClass="text-red-500"
          ></InputTextBox>
          <div class="my-2  w-full">
            <loading v-show="fetching" />
          </div>
          <div class="my-2 w-full" v-if="GET_ARTICLES.length>0">
            <h5 class="font-subtitle-2 text-subtitle-1 mt-1 mb-2 text-gray-500"><small>Click to add article below.</small></h5>
            <div class="grid grid-cols-3 gap-4">
              <div class="bg-background-50 rounded shadow p-3" v-for="(item,index) in GET_ARTICLES" v-bind:key="item.id"  @click="pickArticle(index)">
                <small>{{ item.article_title }}</small>
                <hr class="border-gray-200" />
                <small class="text-gray-500 mt-2">published at: {{ moment(item.published_at).format('DD/MM/YYYY') }}</small>
              </div>
            </div>
          </div>
          <div class="my-2 w-full" v-if="selected_article !== null">
            <h5 class="font-subtitle-2 text-subtitle-1 mt-1 mb-2 text-gray-500"><small>Selected:</small></h5>
            <div class="grid grid-cols-3 gap-4">
              <div class="bg-primary text-white rounded shadow p-3">
                <small>{{ selected_article.article_title }}</small>
                <hr class="border-gray-200" />
                <small class="text-gray-500 mt-2">published at: {{ moment(selected_article.published_at).format('DD/MM/YYYY') }}</small>
              </div>
            </div>
          </div>
          <div class="my-2 w-full" v-if="selected_article !== null">
            <h5 class="font-subtitle-2 text-subtitle-1 mt-1 mb-2 text-gray-500"><small>Main Content Text Replacement</small></h5>
            <InputTextArea
              placeholder="Type here to replace main content text"
              @input="val => processValue(val,'main_content_replacement')"
              :value="form.main_content_replacement"
              :warning="warning.main_content_replacement"
              class="border border-gray-300"
              warningClass="text-red-500"
            ></InputTextArea>
          </div>
          <div class="my-2  w-full">
            <loading v-show="!TARGET_ARTICLE_STATE" />
          </div>
          <div class="mt-20 w-full py-5" v-if="this.form.replacements.length > 0">
            <div class="mb-4"><strong>Content Replacement:</strong></div>
            <div class="w-full my-10" v-for="article,index in this.form.replacements" :key="'article'+article.article_id">
              <div class="w-full"><strong>{{ article.article_title }}</strong></div>
              <InputTextArea
                placeholder="Type here to replace content text"
                @input="val => processValue({value: val, index: index},'replacement')"
                :value="article.content"
                class="border border-gray-300"
                warningClass="text-red-500"
              ></InputTextArea>
            </div>
          </div>
          <!-- <div class="my-2 w-full py-5">
            <hr />
          </div> -->
          <div class="my-2 w-full pt-20">
            <div class="mb-4"><strong>Image After Main Content [CHART SECTION]</strong></div>
            <img v-if="hasImageContent()" width="400" height="140" style="object-fit:contain !important;max-width:600px;max-height:340px;" :src="getImage(GET_NEWSLETTER.newsletter.image_after_main_content)" />
            <div class="inline-block w-1/2 align-top">
              <p class="font-body-2 text-body-2 text-gray-500">
                Maximum upload file size, 200 KB Format image: JPG, JPEG, PNG.<br />
                Recommended image ratio: <br/>- 640px * 320px <br/>- 1024px * 768px
              </p>
            </div>
            <InputImage class="inline-block w-1/2"
              @file-added="(file) => fileAdded(file)"
              @file-removed="() => fileRemoved()"
              :warning="warning.thumbnail"
              warningClass="text-red-500"
            />
          </div>
          <div class="my-2 w-full pt-10">
            <div class="mb-4"><strong>Chart title [CHART SECTION]</strong></div>
            <InputTextBox
              placeholder="Last content title"
              @input="val => processValue(val,'title_after_main_content')"
              :value="form.title_after_main_content"
              :warning="warning.title_after_main_content"
              warningClass="text-red-500"
            ></InputTextBox>
          </div>
          <div class="my-2 w-full pt-10">
            <div class="mb-4"><strong>First Paragraph [CHART SECTION]</strong></div>
            <InputTextArea
              placeholder="Last content"
              @input="val => processValue(val,'first_paragraph_content_after_main_content')"
              :value="form.first_paragraph_content_after_main_content"
              :warning="warning.first_paragraph_content_after_main_content"
              class="border border-gray-300"
              warningClass="text-red-500"
            ></InputTextArea>
          </div>
          <div class="my-2 w-full pt-10">
            <div class="mb-4"><strong>Second Paragraph [CHART SECTION]</strong></div>
            <InputTextArea
              placeholder="Last content"
              @input="val => processValue(val,'second_paragraph_content_after_main_content')"
              :value="form.second_paragraph_content_after_main_content"
              :warning="warning.second_paragraph_content_after_main_content"
              class="border border-gray-300"
              warningClass="text-red-500"
            ></InputTextArea>
          </div>
          <div class="my-2 w-full pt-10">
            <div class="mb-4"><strong>Last Content Title [BOTTOM SECTION]</strong></div>
            <InputTextBox
              placeholder="Last content title"
              @input="val => processValue(val,'last_content_title')"
              :value="form.last_content_title"
              :warning="warning.last_content_title"
              warningClass="text-red-500"
            ></InputTextBox>
          </div>
          <div class="my-2 w-full pt-10">
            <div class="mb-4"><strong>Last Content - First Paragraph [BOTTOM SECTION]</strong></div>
            <InputTextArea
              placeholder="Last content"
              @input="val => processValue(val,'last_content')"
              :value="form.last_content"
              :warning="warning.last_content"
              class="border border-gray-300"
              warningClass="text-red-500"
            ></InputTextArea>
          </div>
          <div class="my-2 w-full pt-10">
            <div class="mb-4"><strong>Last Content - Second Paragraph [BOTTOM SECTION]</strong></div>
            <InputTextArea
              placeholder="Last content"
              @input="val => processValue(val,'second_last_content')"
              :value="form.second_last_content"
              :warning="warning.second_last_content"
              class="border border-gray-300"
              warningClass="text-red-500"
            ></InputTextArea>
          </div>
          <div class="my-2 w-full pt-10">
            <div class="mb-4"><strong>Last Content - Third Paragraph [BOTTOM SECTION]</strong></div>
            <InputTextArea
              placeholder="Third Last content"
              @input="val => processValue(val,'third_last_content')"
              :value="form.third_last_content"
              :warning="warning.third_last_content"
              class="border border-gray-300"
              warningClass="text-red-500"
            ></InputTextArea>
          </div>
          <div class="my-2 w-full pt-10">
            <ButtonSolidBox
              class="py-4 w-full"
              color="secondary"
              @click="submitForm()"
              v-show="true"
            >
              Save
            </ButtonSolidBox>
          </div>
          <!-- <div class="my-2 w-full pt-10">
            <ButtonSolidBox
              class="py-4 w-full"
              color="primary"
              @click="preview()"
              v-show="true"
            >
              VIEW
            </ButtonSolidBox>
          </div> -->
        </div>
        <div class="flex flex-col w-full border rounded p-5" v-show="GET_PREVIEW_READY" :class="[hasSent()?'mt-5' : ' mt-20']">
          <div class="mb-4"><strong>PREVIEW</strong></div>
          <iframe class="w-full" height="800px" :srcdoc="GET_NEWSLETTER_PREVIEW"></iframe>
        </div>
      </div>
    </div>
    <!-- <TheSideCalendarBar :range="range" hide-today @date-updated="(val) => dateRangeUpdated(val)" :show-button-toggle="true" :date-default-state="dateState" @date-state-changed="(val) => dateFilterState(val)" /> -->
  </div>
</template>

<script>
// import ListMenuBar from '@/components/List/ListMenuBar.vue'
// import TheSideCalendarBar from '@/components/TheSideCalendarBar.vue'
// import SimplePagination from '@/components/Paginate/SimplePagination.vue'
import Loading from '@/components/Loading.vue'
import ButtonSolidBox from '@/components/Buttons/ButtonSolidBox.vue'
import InputTextBox from '@/components/Inputs/InputTextBox.vue'
import InputTextArea from '@/components/Inputs/InputTextArea.vue'
import TheSideNavigationBar from '@/components/TheSideNavigationBar.vue'
import InputImage from '@/components/Inputs/InputImage.vue'
import TheContentTopNavigationBar from '@/components/TheContentTopNavigationBar.vue'
import Alert from '@/components/Alert.vue'
import { createNamespacedHelpers } from 'vuex'

const newsletter = createNamespacedHelpers('newsletter')
const article = createNamespacedHelpers('article')

export default {
  components: {
    // ListMenuBar: ListMenuBar,
    Alert,
    InputImage,
    ButtonSolidBox,
    InputTextBox,
    InputTextArea,
    Loading,
    TheSideNavigationBar,
    TheContentTopNavigationBar
    // TheSideCalendarBar,
    // SimplePagination
  },
  computed: {
    ...newsletter.mapGetters(['GET_NEWSLETTER', 'NEWSLETTER_READY', 'GET_UPDATE_STATE', 'TARGET_ARTICLE_STATE', 'GET_NEWSLETTER_ARTICLES', 'GET_NEWSLETTER_PREVIEW', 'GET_PREVIEW_READY']),
    ...article.mapGetters(['ARTICLE_READY', 'GET_ARTICLES'])
  },
  methods: {
    ...newsletter.mapActions(['getCurrentNewsletter', 'updateNewsletter', 'getTargetedArticles', 'getNewsLetterPreview', 'clearTargetArticles']),
    ...article.mapActions(['findArticles', 'createInit', 'clearArticleList']),
    getLayout () {
      return '<div>test</div>'
    },
    hasSent () {
      return this.GET_NEWSLETTER !== null && this.GET_NEWSLETTER.newsletter !== null && this.GET_NEWSLETTER.newsletter.subject !== null
    },
    pickArticle (index) {
      this.form.main_article_id = this.GET_ARTICLES[index].id
      this.selected_article = Object.assign({}, this.GET_ARTICLES[index])
      this.clearArticleList()
    },
    fileAdded (file) {
      this.form.thumbnail = file
      this.warning.thumbnail = ''
    },
    hasImageContent () {
      return this.GET_NEWSLETTER !== null && this.GET_NEWSLETTER.newsletter !== null && this.GET_NEWSLETTER.newsletter.image_after_main_content !== null
    },
    fileRemoved () {
      this.form.thumbnail = ''
      this.warning.thumbnail = 'This field is required'
    },
    getImage (path) {
      return process.env.VUE_APP_IMAGE_URL + '/' + path
    },
    processValue (value, target) {
      if (target === 'replacement') {
        this.form.replacements[value.index].content = value.value
        return
      }
      if (value === '') {
        if (target === 'main_content_replacement') {
          this.form[target] = null
        } else {
          this.warning[target] = 'This field is required'
          this.article_title = ''
        }
      } else {
        if (this.warning[target] !== '') {
          this.warning[target] = ''
        }
        if (target === 'article_title' && value.length > 3) {
          // console.log('find article with key: ' + value)
          if (this.fetching) {
            if (this.queue.length > 0) {
              this.queue[0] = { value: value, target: target }
            } else {
              this.queue.push({ value: value, target: target })
            }
            return
          }
          this.fetching = true
          this.clearArticleList()
          this.findArticles({ data: { find: value } }).then(() => {
            this.fetching = false
            if (this.queue.length > 0) {
              this.processValue(this.queue[0].value, this.queue[0].target)
              this.queue.pop()
            }
          })
          return
        }
        if (target === 'article_title' && value.length < 4 && this.GET_ARTICLES.length > 0) {
          this.clearArticleList()
        }
        this.form[target] = value
      }
    },
    validate () {
      var validation = true
      Object.keys(this.form).forEach(val => {
        if (this.form[val] === '') {
          validation = false
          this.warning[val] = 'This field is required'
        }
      })
      return validation
    },
    submitForm () {
      if (this.validate()) {
        this.commit()
      }
    },
    commit () {
      this.storeError = false
      var formData = new FormData()
      Object.keys(this.form).forEach(val => {
        let datavalue = this.form[val]
        if (val === 'replacements') {
          datavalue = JSON.stringify(datavalue)
        }
        formData.append(val, datavalue)
      })
      this.updateNewsletter({ form: formData }).then((data) => {
        if (data.success) {
          this.showSuccess = true
          this.init()
          setTimeout(() => {
            this.showSuccess = false
          }, 3000)
        }
      }).catch(err => {
      // console.log(err)
        if (err.response !== undefined && err.response.status === 422) {
          err.response.data.data.forEach(val => {
            this.warning[val.path] = val.message
          })
        }
        this.storeError = true
      })
    },
    init () {
      this.getCurrentNewsletter().then((data) => {
        if (data.newsletter !== null) {
          this.form.main_article_id = data.newsletter.main_content_id
          this.form.main_content_replacement = data.newsletter.main_content_replacement
          this.form.title_after_main_content = data.newsletter.title_after_main_content
          this.form.first_paragraph_content_after_main_content = data.newsletter.first_paragraph_content_after_main_content
          this.form.second_paragraph_content_after_main_content = data.newsletter.second_paragraph_content_after_main_content
          this.form.last_content_title = data.newsletter.last_content_title
          this.form.last_content = data.newsletter.last_content
          this.form.second_last_content = data.newsletter.second_last_content
          this.form.third_last_content = data.newsletter.third_last_content
        }

        if (data.main_content !== null) {
          this.selected_article = data.main_content
        }
      })
      this.clearTargetArticles()
      this.getTargetedArticles().then((result) => {
        this.form.replacements = []
        result.data.forEach(data => {
          this.form.replacements.push({
            article_id: data.id,
            content: data.replacement !== null ? data.replacement.content : '',
            article_title: data.article_title
          })
        })
      })
      this.getNewsLetterPreview()
    }
  },
  created () {
    // this.getNewsletterArticles()
  },
  mounted () {
    this.init()
    if (this.GET_ARTICLES.length > 0) {
      this.clearArticleList()
    }
  },
  data () {
    return {
      queue: [],
      fetching: false,
      article_title: '',
      warning: {},
      form: {
        main_article_id: null,
        main_content_replacement: null,
        last_content_title: null,
        last_content: null,
        replacements: []
      },
      selected_article: null,
      showSuccess: false
    }
  }
}
</script>
<style scoped>
@media (min-width: 1440px) {
}</style>
